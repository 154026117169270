<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title"> Edit {{vmConfigMenuFormData.config_menu_type}}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmConfigMenuFormData && Object.keys(vmConfigMenuFormData).length > 0">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_name">
                    {{cvConfigMenuNameLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_icon">
                    {{cvConfigMenuIconLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_icon"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_link">
                    {{cvConfigMenuGroupLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_grp"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_link">
                    {{cvConfigMenuLinkLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_link"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                <label for="validationconfig_menu_type">
                  {{cvConfigMenuTypeLabel}}</label
                >
                <select class="form-control"  v-model="vmConfigMenuFormData.config_menu_type">
                  <option  v-for="(menu_type, index) of menuType" :key="(index+1)" :value="index" >
                    {{menu_type}}
                  </option>
                </select>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="configMenuEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ConfigMenus } from "../../../FackApi/api/ConfigMenu"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ConfigMenuEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propConfigMenuObj: {
      type: Object,
      default: function () {
        return {
          "config_menu_name": "",
          "config_menu_icon": "",
          "config_menu_link": "",
          "config_menu_grp": "",
          "config_menu_type": ""
        }
      }
    }
  },
  data () {
    return {
      cvSubmitBtn: "Edit",
      cvConfigMenuNameLabel: "menu name",
      cvConfigMenuIconLabel: "menu icon",
      cvConfigMenuLinkLabel: "menu link",
      cvConfigMenuTypeLabel: "menu type",
      cvConfigMenuGroupLabel: "menu Group",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: " Updated",
      vmConfigMenuFormData: {},
      menuType: {
        Configurations: "Configurations",
        Reports: "Reports",
        Resources: "Resources"
      }
    }
  },
  mounted () {
    socialvue.index()
    this.configMenuView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmConfigMenuFormData) {
          if (!this.vmConfigMenuFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * configMenuView
     */
    async configMenuView () {
      try {
        if (this.propOpenedInModal) {
          this.vmConfigMenuFormData = this.propConfigMenuObj
        }
        else {
          let configMenuId = this.$route.params.acm_id
          let configMenuViewResp = await ConfigMenus.configMenuView(this, configMenuId)

          if (configMenuViewResp && configMenuViewResp.resp_status) {
            this.vmConfigMenuFormData = configMenuViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at configMenuView() and Exception:", err.message)
      }
    },
    /**
     * configMenuEdit
     */
    async configMenuEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let configMenuAddResp = await ConfigMenus.configMenuEdit(this, this.vmConfigMenuFormData)
        await ApiResponse.responseMessageDisplay(this, configMenuAddResp)

        if (configMenuAddResp && !configMenuAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseConfigMenuEditModal", this.vmConfigMenuFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at configMenuEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
